import { IconProps } from "components/Utils/Icon.props"

export const iconsSrcMap: {
  [key: string]: IconProps
} = {
  customerServiceCall: {
    name: "CustomerServiceCall.svg",
    color: "tiffany-400"
  },
  customerServiceChat: {
    name: "CustomerServiceChat.svg",
    color: "tiffany-400"
  },
  customerServiceFaq: { name: "CustomerServiceFaq.svg", color: "tiffany-400" },
  customerServiceMail: {
    name: "CustomerServiceMail.svg",
    color: "tiffany-400"
  },
  customerServiceOrder: {
    name: "CustomerServiceOrder.svg",
    color: "tiffany-400"
  },
  customerServiceReceipt: {
    name: "CustomerServiceReceipt.svg",
    color: "tiffany-400"
  },
  call: { name: "Call.svg", color: "tiffany-400" },
  chat: { name: "Chat.svg", color: "tiffany-400" },
  mail: { name: "Mail.svg", color: "tiffany-400" }
}
